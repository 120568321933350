import React, { Component } from 'react';
import { SERVER_URL } from '../config.js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar'; 
import logo from './assets/logo_large.jpg';
import innovacion from './assets/innovacion.png';
import LogoSpin from './LoadingAnimation/LogoSpin';
import httpHandleError from './handlers/httpErrorHandler'
import PermissionsContext from './global/PermissionsContext.js';
import {withRouter} from '../withRouter.js'

class Login extends Component {

    componentDidMount = () => {
        sessionStorage.removeItem("jwt");
    }

    constructor(props){
        super(props);
        this.state = {
            username : '',
            password : '',
            isAuthenticated: false,
            open : false,
            loading : false,
            animationStartTime : 0,
            updated : false
        }
    }

    static contextType  = PermissionsContext;

    handleChange = (event) => {
        this.setState({[event.target.name] : event.target.value});
    }

    handleClose = () => {
        this.setState({ open:false })
    }

    login = (setPermissions) => {
        const user = {username : this.state.username , password : this.state.password};

        fetch(SERVER_URL + `login`,{
            method : 'POST',
            body: JSON.stringify(user)
        })
        .then(res => {
            const  jwtToken = res.headers.get('Authorization')
            if(jwtToken !== null){
                this.setState({
                    loading : true,
                    animationStartTime : (new Date).getTime()    
                });
                sessionStorage.setItem("jwt", jwtToken);
                this.setState({isAuthenticated : true});
                this.retrievePermissions(jwtToken, setPermissions);
            }else{
                this.setState({open : true});
            }
        })
        .catch(err => console.error(err))
    }

    updateSensors = () => {
        const jwtToken = sessionStorage.getItem("jwt");
          console.log("Updating sensors");
          fetch(SERVER_URL + `sensors/update`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': jwtToken
            }
          })
            .then((response) => response.json())
            .then((responseData => {
                console.log("synced");  
            }))
            .catch(err => console.error(err));
    }


    
    retrievePermissions = (jwtToken, setPermissions) => {
        fetch(SERVER_URL + 'loggedinuser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        })
        .then(httpHandleError)
        .then((response) => response.json())
        .then((responseData => {
            sessionStorage.setItem("username",responseData.username);
            sessionStorage.setItem("isAdmin",responseData.admin);
            
            console.log("Authorization level");


            let wait = 2000; //Wait 2 secons of animation
            if((new Date).getTime() > (this.state.animationStartTime + 2000)){
                //if fetch already has taken 2 seconds or more better not wait long
                wait = 0;
            }
            setTimeout(
                function() {
                    this.setState({loading: false});
                    setPermissions([true, responseData.admin]);
                }
                .bind(this),
                wait
            );
        }))
        .catch(err => {
            this.setState({loading : false});
            switch(err.message){
                case "403":
                    console.log("Forbidden");
                    break;
                case "500":
                    alert("Internal Server Error. Status code 500. Contact Administrator.");
                    break;
                default:
                    alert("Unknown error");
                    break;
            }
        });
    }

    render() {
        if(this.state.loading & !this.state.updated){
            this.setState({updated : true});
            this.updateSensors();
        }
        return(
            <PermissionsContext.Consumer>
                {({permissions, setPermissions}) => (
                    <div>
                        {this.state.loading === true &&
                            <LogoSpin/>
                        }
                        {this.state.loading === false && this.state.isAuthenticated === true &&
                            <Navigate to={{ pathname: '/', state: { from: this.props.location } }} />
                        }
                        {this.state.loading === false && this.state.isAuthenticated === false &&
                            <div style={{height:"100%"}}>
                                <div style={{margin : 40}}>
                                <img src={logo} alt="logo" style={{width:300}}/>
                                </div>
                                <div>
                                    <TextField name="username" placeholder="Username" onChange={this.handleChange} style={{width:200}}/><br/>
                                    <TextField type="password" name="password" placeholder="Password" onChange={this.handleChange} style={{width:200}}/><br/><br/>
                                    <Button variant="contained" color="primary" onClick={() => this.login(setPermissions)} style={{width:200}}>Login</Button>
                                    <Snackbar open={this.state.open} onClose={this.handleClose} autoHideDuration={1500} message='Check your username and password' />
                                </div>
                                <div style={{position:"absolute", bottom: 50, width:"100%", display:"flex", justifyContent: "center", alignItems: "center"}}>
                                    <div style={{color:"#838383", marginLeft: "auto", marginRight:"auto"}}>
                                        <div>Desarrollado por</div>
                                        <img src={innovacion} alt="logo-developer" style={{width:300}}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}
            </PermissionsContext.Consumer>
        );
            
    }
}

export default(
    withRouter
  )(Login);