import React, { useEffect, useState, useRef } from 'react';
import { SERVER_URL } from '../config.js';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@mui/material/IconButton';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
  } from 'chart.js';
  import Chart from 'chart.js/auto';
  import { Bar, Radar } from 'react-chartjs-2';
  import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
  import Card from '@mui/material/Card';
  import CardActions from '@mui/material/CardActions';
  import CardContent from '@mui/material/CardContent';
  import Icon from '@mui/material/Icon';
  import BatteryFullIcon from '@mui/icons-material/BatteryFull';
  import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import { TextField } from '@mui/material';
  import dayjs from 'dayjs';
  import Select from '@mui/material/Select';
  import MenuItem from '@mui/material/MenuItem';

  let emptySensor = {
      serial : '',
      ruta: '',
      updatedAt: '',
      batteryChart: 1,
      pic: ''
  }

export default function Sensor() {

    Chart.register(CategoryScale,
        RadialLinearScale,
        PointElement,
        LineElement,
        Filler,
        Tooltip,
        Legend);

    

    const { id } = useParams();
    const [yearDataset1, setYearDataset1] = React.useState([]);
    const [yearDataset2, setYearDataset2] = React.useState([]);
    const [hourlyDataset1, setHourlyDataset1] = React.useState([]);
    const [hourlyDataset2, setHourlyDataset2] = React.useState([]);
    const [monthDataset1, setMonthDataset1] = React.useState([]);
    const [monthDataset2, setMonthDataset2] = React.useState([]);
    const [sumMonth1, setSumMonth1] = React.useState(0);
    const [sumMonth2, setSumMonth2] = React.useState(0);
    const [sensor, setSensor] = React.useState(emptySensor);
    const [startDate, setStartDate] =  React.useState(dayjs());
    const [sensors, setSensors] = React.useState([]);
    const [sensorSelected, setSensorSelected] = React.useState(id);

    const navigate = useNavigate();

    const labels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
      /*
    const screenSize = getCurrentDimension();

    const isMobile = screenSize.width <= 768;

    

  	function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

    const updateDimension = () => {
      screenSize = getCurrentDimension();
    }*/

    useEffect(() => {
        fetchYear();
        fetchHourly();
        fetchMonth();
        fetchSumMonth();
        fetchSensor();
        fetchSensors();
        
        //window.addEventListener('resize', updateDimension);
        //return(() => {
        //    window.removeEventListener('resize', updateDimension);
        //})
      },[]);

      const fetchSensors = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors?unique=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              setSensors(responseData);
          }))
          .catch(err => console.error(err));
    } 

    const fetchYear = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `records/${id}/year`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              setYearDataset1(responseData.d1);
              setYearDataset2(responseData.d2);
          }))
          .catch(err => console.error(err));
    } 

    const fetchHourly = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `records/${id}/hourlymeans`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              setHourlyDataset1(responseData.d1);
              setHourlyDataset2(responseData.d2);
          }))
          .catch(err => console.error(err));
    }

    const fetchMonth = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `records/${id}/month`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              setMonthDataset1(responseData.d1);
              setMonthDataset2(responseData.d2);
          }))
          .catch(err => console.error(err));
    } 

    function filterDate(date) {
      setStartDate(date);
      console.log(date.format('YYYY-MM'));
      const jwtToken = sessionStorage.getItem("jwt");
    
      //Use same endpoint as plot because are same params
      fetch(SERVER_URL + `records/${id}/month?datemonth=${date.format('YYYY-MM')}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
  
        }
      })
        .then((response) => response.json())
        .then((responseData => {
            console.log(responseData);
            setMonthDataset1(responseData.d1);
            setMonthDataset2(responseData.d2);
        }))
        .catch(err => console.error(err));
    }

    const fetchSumMonth = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `records/${id}/summonth`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              setSumMonth1(responseData[0]);
              setSumMonth2(responseData[1]);
          }))
          .catch(err => console.error(err));
    } 

    const updateRecords = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `records/${id}/update`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) =>  {
              console.log("records updated");
              fetchYear();
              fetchHourly();
              fetchMonth();
              fetchSumMonth();
              fetchSensor();
          })
          .catch(err => console.error(err));
    } 

    const getReport = () => {
        const jwtToken = sessionStorage.getItem("jwt");
        toast("Generando CSV... Por favor espera", {position: toast.POSITION.BOTTOM_LEFT, autoClose: false})
        fetch(SERVER_URL + `records/${id}/report`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization' : jwtToken
                },
            })
            .then((response) => //{
                //if(!response.ok){
                //    toast.error("Internal Server Error", { position: toast.POSITION.BOTTOM_LEFT})
                //}
                response.blob()
            //}
            )
            .then((blob) => {
                toast.dismiss();

                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                var tempDate = new Date();
                var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +'_'+ tempDate.getHours()+''+ tempDate.getMinutes();
                link.setAttribute('download', `Datos_Contador_${date}.csv`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
        .catch (err => console.error(err));
    }

    const handleClickUpdate = () => {
        updateRecords(); 
    }

    const fetchSensor = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              setSensor(responseData);
          }))
          .catch(err => console.error(err));
    } 

    const handleChange = (event) => {
      setSensorSelected(event.target.value);
      navigate(`/sensors/${event.target.value}`, { replace: true });
      navigate(0);
    }

    const yearOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
            display: true,
            text: 'Datos anuales',
            },
        },
    };

    const yearData = {
        labels,
        datasets: [
          {
            label: 'Entradas',
            data: yearDataset1,
            backgroundColor: 'rgba(105, 182, 76, 0.7)',
          },
          {
            label: 'Salidas',
            data: yearDataset2,
            backgroundColor: 'rgba(81, 140, 58, 0.7)',
          },
        ],
    };

    const hourlyOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
            display: true,
            text: 'Media visitas por hora',
            },
        },
    };

    const hourlyData = {
        labels: ['00', '01', '02', '03', '04', '05','06', '07', '08', '09', '10', '11',
                    '12', '13', '14', '15', '16', '17','18', '19', '20', '21', '22', '23'],
        datasets: [
          {
            label: 'Entradas',
            data: hourlyDataset1,
            backgroundColor: 'rgba(105, 182, 76, 0.4)',
            borderColor: 'rgba(105, 182, 76, 1)',
            borderWidth: 1,
          },
          {
            label: 'Salidas',
            data: hourlyDataset2,
            backgroundColor: 'rgba(71,122,51, 0.4)',
            borderColor: 'rgba(71,122,51, 1)',
            borderWidth: 1,
          }
        ],
    };

    const monthOptions = {
        maintainAspectRatio: false, 
        responsive: true,
        plugins: {
            legend: {
            position: 'top',
            },
            title: {
            display: true,
            text: 'Visitas del mes',
            },
        },
    };

    const monthData = {
        labels: monthDataset1.map((value, index) => index+1 ),
        datasets: [
          {
            label: 'Entradas',
            data: monthDataset1,
            backgroundColor: 'rgba(105, 182, 76, 0.7)',
          },
          {
            label: 'Salidas',
            data: monthDataset2,
            backgroundColor: 'rgba(81,140,58, 0.7)',
          },
        ],
    };


    return(
        <div style={{width:'100%',height:'100%'}}>
            <Grid container sx={{height:'94%'}}>
                <Grid item xs={12} sm={4} sx={{padding:'12px', height:'47%'}}>
                    <Card sx={{ minWidth: 200, minHeight:355, display:'flex', flexDirection : 'column', justifyContent: 'space-between' }} style={{margin:30,height:'100%'}}>
                        <CardContent sx={{paddingBottom:0}}>
                            <Select
                              labelId="sensor"
                              id="sensorid"
                              value={sensorSelected}
                              label="Sensor"
                              onChange={handleChange}
                            >
                              {sensors.map((item) => {
                                return(<MenuItem value={item.id}>{item.ruta}</MenuItem>)
                              })}
                            </Select>
                            <h2>{sensor.code}</h2>
                            <h3>Entradas totales (mes): {sumMonth1}</h3>
                            <h3>Salidas totales (mes): {sumMonth2}</h3>
                            <p>Actualizado: {sensor.updatedAt}</p>
                            <p>Serial: {sensor.serial.substring(8)}</p>
                            
                        </CardContent>
                        <CardActions sx={{paddingTop:0}}>
                            {sensor.batteryCharge === 0 && <Icon><Battery0BarIcon color="primary"/></Icon>}
                            {sensor.batteryCharge === 1 && <Icon><BatteryFullIcon color="primary"/></Icon>}
                            <IconButton aria-label="update" onClick={() => handleClickUpdate(true)}>
                                <UpdateIcon color="primary" />
                            </IconButton>
                            <IconButton aria-label="download" onClick={getReport}>Informe</IconButton>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8} sx={{padding: '12px', height:'47%',marginTop:{xs:'40px', md:'0px'}}}>
                    <Bar options={yearOptions} data={yearData} />
                </Grid>
                <Grid item xs={12} sm={4} sx={{padding: '12px', height:'47%'}}>
                    <Radar  options={hourlyOptions} data={hourlyData} />
                </Grid>
                <Grid item xs={12} sm={8} sx={{padding: '12px', height:'44%'}}>
                    <Bar options={monthOptions} data={monthData} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="yyyy-MM"
                        views={['year', 'month']}
                        label="Año y mes"
                        minDate={dayjs("2023-01-01")}
                        maxDate={dayjs("2030-01-01")}
                        value={startDate}
                        onChange={(e) => filterDate(e) }
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                      />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </div>
    );
}