import React, { useEffect, useState, useRef } from 'react';
import { SERVER_URL } from '../config.js';
import { ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/base';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import UpdateIcon from '@mui/icons-material/Update';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import WifiIcon from '@mui/icons-material/Wifi';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ConstructionOutlined } from '@mui/icons-material';
import { pink } from '@mui/material/colors';
import { differenceInDays, parse } from 'date-fns';
import { GridToolbar } from '@mui/x-data-grid';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

export default function SensorList() {

    const navigate = useNavigate();
    const [sensors, setSensors] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [newSerial, setNewSerial] = React.useState('');
    const [newRuta, setNewRuta] = React.useState('');
    const [newOnline, setNewOnline] = React.useState(true);
    const [x, setX] = React.useState('');
    const [y, setY] = React.useState('');
    const [newCode,setNewCode] = React.useState('')
    const [serialErrorMessage, setSerialErrorMessage] = React.useState("");
    const [coordinatesErrorMessage, setCoordinatesErrorMessage] = React.useState("");
    const [alignment, setAlignment] = React.useState('online');
    const hiddenFileInput = useRef({});
    const [images, setImages] = React.useState([]);
    const [openImage, setOpenImage] = React.useState(false);
    const [clickedId, setClickedId] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("loading");
  

    useEffect(() => {
        fetchSensors();
      }, []);

    const fetchSensors = () => {
        const jwtToken = sessionStorage.getItem("jwt");
    
        //Use same endpoint as plot because are same params
        fetch(SERVER_URL + `sensors?unique=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
    
          }
        })
          .then((response) => response.json())
          .then((responseData => {
              console.log(responseData);
              
              setSensors(responseData);
              let imagesIds = [];
              for(let i = 0; i < responseData.length; i++){
                imagesIds.push(responseData[i].id);
              }
              fetchImages(imagesIds);
          }))
          .catch(err => console.error(err));
    } 

    const deleteSensor = (id) => {
      const jwtToken = sessionStorage.getItem("jwt");
  
      //Use same endpoint as plot because are same params
      fetch(SERVER_URL + `sensors/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      })
        .then((response) => response.json())
        .then((responseData => {
            console.log("deleted");
            fetchSensors();
        }))
        .catch(err => console.error(err));
  } 

  const updateSensors = (fetchAgain) => {
    setLoading(true);
    setMessage("Sincronizando")
    const jwtToken = sessionStorage.getItem("jwt");
      console.log("Updating sensors");
      fetch(SERVER_URL + `sensors/update`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      })
        .then((response) => response.json())
        .then((responseData => {
            console.log("synced");
            if(fetchAgain){
              fetchSensors();
            }
            sanitize();
            setLoading(false);
        }))
        .catch(err => {
          console.log("error updating sensors, trying sanitize");
          setMessage("Error de consistencia, reparando...")
          sanitize();
          console.error(err)
        });

  }

  const sanitize = () => {
    const jwtToken = sessionStorage.getItem("jwt");
      console.log("Sanitizing records");
      fetch(SERVER_URL + `sensors/sanitize`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': jwtToken
        }
      })
        .then((response) => response.json())
        .then((responseData => {
            if(responseData == 1){
              console.log("DB was sanitized");
              updateSensors(false);
            }else{
              console.log("No records affected");
            }            
        }))
        .catch(err => console.error(err));
  }

  const getDaysFromReview = (sensor) => {
    const currentDate = new Date();
    let days = 0;
    if(sensor.online){
      if(sensor.updatedAt === undefined || sensor.updatedAt == null){
        return 0;
      }else{
        const updatedAtDate = parse(sensor.updatedAt.substring(0,10), 'yyyy-MM-dd', new Date());
        //console.log("fecha 1: "+updatedAtDate);
        days = differenceInDays(currentDate, updatedAtDate);
        
      }
    }else{
      if(sensor.updatedAt === undefined || sensor.updatedAt == null){
        return 0
      }
      const lastReviewedDate = parse(sensor.updatedAt.substring(0,10), 'yyyy-MM-dd', new Date());
      //console.log("fecha 1: "+lastReviewedDate);
      days = differenceInDays(currentDate, lastReviewedDate);
    }
    //console.log("days "+days);
    return days;
  }

  const postFiles = (formData,name, rowid) => {
    toast("Parsing sdb file...Please wait",{position: toast.POSITION.BOTTOM_LEFT, autoClose: false});
    const jwtToken = sessionStorage.getItem("jwt");
    console.log("ID "+rowid);
    fetch(SERVER_URL + `sensors/${rowid}/file?name=${name}`, {
        method: 'POST',
        headers:{
            'Authorization' : jwtToken
        },
        body: formData
    }).then(res => {
        toast.dismiss();
        if(res.ok) {
            fetchSensors();
            alert("File uploaded successfully.")
        }else{
            switch(res.status){
                case 400:
                    alert("Unsupported Excel Format. Try to open and save the file with MS Excel.")
                    break;
                case 415:
                    alert("Bad Excel Data Format. Unexpected Data.");
                    break;
                case 404:
                    alert("Error: Compound not found. Check all products referenced in the file are present in DB.");
                    break;
                case 507:
                    alert("Storage error. System could be full. Contact Administrator.");
                    break;    
                case 500:
                    alert("Internal Server Error. Status code 500. Contact Administrator.");
                    break;
                default:
                    alert("Unknown error");
                    break;
            }
        }
    }).catch(err => {
        alert("Query error");
    });
  }

  const addSensor = () => {
    const jwtToken = sessionStorage.getItem("jwt");
    var onl = false;
    if(alignment==='online'){
      onl = true;
    }
    let sensor = {
      serial : newSerial,
      ruta: newRuta,
      online: onl,
      locationX: x,
      locationY: y,
      code: newCode
    }
    console.log(sensor);
    //Use same endpoint as plot because are same params
    fetch(SERVER_URL + `sensors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwtToken
      },
      body: JSON.stringify(sensor)
    })
      .then((response) => {
          console.log("added");
          updateSensors(true);
      })
      .catch(err => console.error(err));
} 

async function fetchImages(ids){
  const jwtToken = sessionStorage.getItem("jwt");
  let i = 0;
  let imagesClone = [];
  for(i = 0; i< ids.length; i++){
    const response = await fetch(SERVER_URL + `sensors/${ids[i]}/picture`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            },
        });
    const blob = await response.blob();    
        

    console.log("image retrieved for "+ids[i]);
    //document.getElementById("imgDiv"+ids[i]).innerHTML = '';
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    //const url = URL.createObjectURL(blob)
    /*const img = document.createElement('img');
    img.setAttribute("src", url);
    img.setAttribute("width", "200");
    img.setAttribute("alt", "image");*/
    imagesClone.push({id:ids[i], src : url});
    // 3. Append to html page
    //document.getElementById("imgDiv"+ids[i]).appendChild(img);
  }
  setImages(imagesClone);
}


    const handleClickOpen = () => {
      console.log("click");
      setOpen(true);
    };
  
    const handleClose = () => {
      setSerialErrorMessage("");
      setCoordinatesErrorMessage("");
      setOpen(false);
    };

    const handleCloseImage = () => {
      setOpenImage(false);
    }
    
    const handleAdd = () => {
      if(validateForm()){
        addSensor();
        setOpen(false);
      }
    }

    const handleClick = (index) => {
      hiddenFileInput.current[index].click();
    };

    const handleClickUpdate = (refresh) => {
      updateSensors(refresh);
      
    }

    const handleChange = (event, newAlignment) => {
      if (newAlignment !== null) {
        setAlignment(newAlignment);
      }
    };

    const onFileChangeHandler = (e, rowid) => {
      e.preventDefault();
      const formData = new FormData();
      for(let i = 0; i< e.target.files.length; i++) {
          formData.append('files', e.target.files[i])
      }
      postFiles(formData, e.target.files[0].name, rowid);  
    };

    const selectFiles = (e) => {
      e.preventDefault();
      this.inputElement.click();
    }

    function validateForm () {
      if(isNaN(newSerial)){
        setSerialErrorMessage("Serial invalido, solo debe contener números");
        console.log("invalido caracteres")
        return false;
      }else if(newSerial.length !== 8){
        setSerialErrorMessage("Longitud de Serial invalida, debe contener 8 caracteres");
        console.log("invalido longitud");
        return false;
      }
      if(isNaN(x) || isNaN(y)){
        setCoordinatesErrorMessage("Coordenadas inválidas. Introduzca solo números y '.'");
        return false;
      }
      setSerialErrorMessage("");
      setCoordinatesErrorMessage("");
      console.log("valido");
      return true;
    }
    /** 
    const renderTable = () => {
      return(
        <div style={{ height: 400, width: '100%' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="Sensores">
              <TableHead>
                <TableRow>
                  <TableCell width={150}>Serial</TableCell>
                  <TableCell width={100}>Código</TableCell>
                  <TableCell width={200}>Ruta</TableCell>
                  <TableCell width={150}>Actualizado</TableCell>
                  <TableCell width={50}>Bateria</TableCell>
                  <TableCell width={50}>Tipo</TableCell>
                  <TableCell width={50}>Upload</TableCell>
                  <TableCell width={100}>Imagen</TableCell>
                  <TableCell width={100}>Editar</TableCell>
                  <TableCell width={100}>Borrar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sensors.map((row, index) => {
                  return(
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    
                    <TableCell component="th" scope="row">
                      <Typography>
                      <Link style={{cursor:'pointer'}} onClick={()=> {
                          navigate(`/sensors/${row.id}`)
                      }}>{row.serial.substring(8)}</Link>
                      </Typography>
                    </TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.ruta}</TableCell>
                    <TableCell>{row.updatedAt != null ? row.updatedAt.substring(0,19) : <></>} ({getDaysFromReview(row)})</TableCell>
                    <TableCell>{row.batteryCharge}</TableCell>
                    <TableCell>{row.online ?(getDaysFromReview(row) > 25 ? <WifiIcon sx={{ color: pink[500] }}/> : <WifiIcon/>) : 
                                  getDaysFromReview(row) > 180 ? <SignalWifiOffIcon sx={{ color: pink[500] }}/> : <SignalWifiOffIcon/> }
                    
                    </TableCell>
                    <TableCell><><IconButton onClick={() => handleClick(index)}><FileUploadIcon/></IconButton>
                                                <input type="file" name={`fileInput${row.id}`} style={{display : "none"}}
                                                  key={row.id} 
                                                  ref={(element) => hiddenFileInput.current[index] = element}
                                                  onChange={(event) => onFileChangeHandler(event,row.id)}
                                                /></> </TableCell>
                    <TableCell><div id={`imgDiv${row.id}`}></div></TableCell>
                    <TableCell>
                      <IconButton 
                        aria-label="editar" 
                        onClick={() => 
                          navigate(`/sensors/${row.id}/edit`)
                          }
                        >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton aria-label="eliminar" onClick={() => deleteSensor(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }
*/
function search(nameKey, myArray){
  for (let i=0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
          return myArray[i];
      }
  }
}

    const renderTable = () => {
      const columns = [
        {
          headerName: 'Código',
          width: 100,
          field: 'code',
          display: 'flex',
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => {
            return(<Typography sx={{fontSize:{xs:15,md:20}}} ><Link style={{cursor:'pointer'}} onClick={() => {navigate(`/sensors/${params.row.id}`)}}>{params.row.code}</Link></Typography>)
          } 
        },{
          headerName: 'Ruta',
          flex:1,
          minWidth:150,
          field: 'ruta',
          headerAlign: 'center',

          display: 'flex',
          renderCell: (params) => {
            return(<Typography sx={{fontSize:{xs:22,md:30}}}>{params.row.ruta}</Typography>)
          } 
        },{
          headerName: 'Actualizado',
          display: 'flex',
          align: 'center',
          field: 'updatedAt',
          renderCell: (params) => {
            return(<Typography sx={{fontSize:{xs:15,md:20}}}>{params.row.updatedAt != null ? params.row.updatedAt.substring(0,19).replace("T"," ") : <></>} ({getDaysFromReview(params.row)})</Typography>)
          }
        },{
          headerName: 'Batería',
          width: 80,
          display: 'flex',
          align: 'center',
          field: 'batteryCharge',
          renderCell: (params) => {
            return(<>{params.row.batteryCharge === 0 ? <Battery0BarIcon sx={{ fontSize : 50}}/> : <BatteryFullIcon sx={{ fontSize : 50}}/>}</>)
          }
        },{
          headerName: 'Tipo',
          width: 80,
          display: 'flex',
          align: 'center',
          field: 'online',
          renderCell: (params) => {
            return(<>{params.row.online ?(getDaysFromReview(params.row) > 25 ? <WifiIcon sx={{ color: pink[500], fontSize: 50 }}/> : <WifiIcon sx={{ fontSize : 50}}/>) : 
            getDaysFromReview(params.row) > 180 ? <SignalWifiOffIcon sx={{ color: pink[500], fontSize : 50 }}/> : <SignalWifiOffIcon sx={{ fontSize : 50}}/> }</>)
          }
        },{
          headerName: 'Upload',
          width:80,
          display: 'flex',
          align: 'center',
          field: 'id',
          renderCell: (params) => {
            return(<><IconButton onClick={() => handleClick(params.row.id)}><FileUploadIcon sx={{ fontSize : 50}}/></IconButton>
              <input type="file" name={`fileInput${params.row.id}`} style={{display : "none"}}
                key={params.row.id} 
                ref={(element) => hiddenFileInput.current[params.row.id] = element}
                onChange={(event) => onFileChangeHandler(event,params.row.id)}
              /></>)
          }
        },{
          headerName: 'Imagen',
          width: 200,
          height: 200,
          field: 'id2',
          display: 'flex',
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => {
            //return(<div id={`imgDiv${params.row.id}`}></div>)
            //return(<>{fetchImage(params.row.id)}</>)
            let obj = images.find(o => o.id === params.row.id)
            if(obj !== null && obj !== undefined){
              console.log(obj);
              return(<div 
                onClick={() => {
                  setClickedId(params.row.id);
                  setOpenImage(true);
                }}
                style={{height:'150px', cursor:"pointer"}}><img width='200' src={obj.src}></img></div>)
            }else{
              return(<div style={{height:'150px'}}><img width='200' ></img></div>)
            }
          }
        },{
          headerName: 'Editar',
          width:80,
          field: 'id3',
          display: 'flex',
          align: 'center',
          renderCell: (params) => {
            return(<IconButton 
              
              aria-label="editar" 
              onClick={() => 
                navigate(`/sensors/${params.row.id}/edit`)
                }
              >
              <EditIcon sx={{ fontSize : 50}}/>
            </IconButton>)
          }
        },{
          headerName: 'Borrar',
          width:80,
          field: 'id4',
          display: 'flex',
          align: 'center',
          renderCell: (params) => {
            return(<IconButton aria-label="eliminar" onClick={() => deleteSensor(params.row.id)}>
                    <DeleteIcon sx={{ fontSize : 50}} />
                  </IconButton>)
          }
        }
      ];

      return(
        <DataGrid style={{marginRight:20}}
            rows={sensors}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 50,
                },
                },
            }}
            pageSizeOptions={[50]}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                },
            }}
            getRowHeight={() => "auto"}
            sx={{
              "& .MuiDataGrid-cellContent": {
                minHeight: 250,
                maxHeight: 280
              }
            }}
        />  
      )    
  }

  
    const renderImageDialog = () => {
      let obj = images.find(o => o.id === clickedId)
      if(obj !== null && obj !== undefined){
        return(
          <Dialog open={openImage} onClose={handleCloseImage} fullScreen>
          <DialogContent>
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseImage}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
            <img src={obj.src}></img>
          </DialogContent>
          </Dialog>
        )
      }else{
        <Dialog openImage={open} onClose={handleClose}>
        <DialogTitle color="primary">Imagen no encontrada</DialogTitle>
        <DialogContent>
          
        </DialogContent>
        </Dialog>
      }
    }

    const renderDialog = () => {
      return(
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary">Nuevo Sensor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Añade un nuevo sensor introduciendo los siguientes datos:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="serial"
            label="Serial"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setNewSerial(event.target.value);
            }}
          />
          <div className="stringError">{serialErrorMessage}</div><br />
          <TextField
            autoFocus
            margin="dense"
            id="code"
            label="Código"
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setNewCode(event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ruta"
            label="Nombre de ruta"
            type="text"
            fullWidth
            variant="standard"
            style={{marginBottom: 20}}
            onChange={(event) => {
              setNewRuta(event.target.value);
            }}
          />
          <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Tipo"
            >
              <ToggleButton value="online">Online</ToggleButton>
              <ToggleButton value="offline">Offline</ToggleButton>
            </ToggleButtonGroup>
          <Typography>lat</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="x"
            label=""
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setX(event.target.value);
            }}
          />
          <Typography>lon</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="y"
            label=""
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setY(event.target.value);
            }}
          />
          <div className="stringError">{coordinatesErrorMessage}</div><br />
        </DialogContent>
        <DialogActions>
          <IconButton color="primary" onClick={handleClose}>Cancelar</IconButton>
          <IconButton color="primary" onClick={handleAdd}>Añadir</IconButton>
        </DialogActions>
      </Dialog>
      );
    }
      

    return(
      <div>

      {loading && <Box><CircularProgress size={90}/><Typography>{message}</Typography></Box>}
      {renderDialog()}
      {renderImageDialog()}
      <Grid container direction="column" >
        <Grid item style={{marginBottom:50}}>
          <h1> Lista de sensores</h1>
          
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <IconButton aria-label="añadir" onClick={handleClickOpen}>
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="eliminar" onClick={handleClickOpen}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton aria-label="update" onClick={() => handleClickUpdate(true)}>
                <UpdateIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {renderTable()}
        </Grid>
      </Grid>
      
      </div>
    );
}